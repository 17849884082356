@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Helvetica";
}

.input-error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.bg-opacity_g {
  background-color: #ffffffed;
}


.bg-dialog-opacity-1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.hiddenImportant {
  display: none !important;
}

/* grid */
.gridHeader {
  grid-area: header;
}
/* .item2 { grid-area: menu; } */
.gridMain {
  grid-area: main;
}
/* .item4 { grid-area: right; } */
.gridFooter {
  grid-area: footer;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "header header header"
    "main main main"
    "footer footer footer";

  grid-template-rows: auto 1fr 4rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiTextField-root {
  margin-bottom: 1rem !important;
}

.border-gradient-primary {
  border-color: linear-gradient(
    90deg,
    rgba(7, 7, 219, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}

.bg-gradient-primary {
  background: linear-gradient(
    90deg,
    rgba(7, 7, 219, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}

/* .MuiTableCell-root{
    font-size: 0.875rem;
} */

/* scrooll bar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(191 219 254);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(56, 138, 238);
}
